<template>
  <UIToast :type="theme.type" class="tw-cursor-pointer" @click="redirectTo(theme)">{{
    theme.text
  }}</UIToast>
</template>
<script setup lang="ts">
const router = useRouter();
const route = useRoute();
const userInfo = useUserInfo();
const KycTheme = computed(()=>({
  [KycStatus.NotVerified]: {
    text: "Your identity verification is not verified.",
    type: "error",
    url: `/kyc?targetUrl=${route.path}`,
  },
  [KycStatus.InReView]: {
    text: "Your identity verification is in review.",
    type: "wait",
  }, //
  [KycStatus.Rejected]: {
    text: "Your identity verification is rejected.",
    type: "error",
  }, //
  [KycStatus.NeedUpdate]: {
    text: "Your identity verification need to be updated",
    type: "info",
    url: `/rfi?targetUrl=${route.path}`,
  }, //
  [KycStatus.Verified]: {
    text: "Your identity verification is verified.",
    type: "success",
  }, //
}));
const theme = computed(() => {
  return KycTheme.value[unref(userInfo.kycStatus)] as {
    text: string;
    type: "success" | "error" | "info" | "wait";
    url?: string;
  };
});

const redirectTo = (item: typeof theme.value) => {
  if (item.url) {
    router.push(item.url);
  }
};
</script>
