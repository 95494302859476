<template>
  <section
    class="tw-text-white tw-text-xs tw-rounded-full tw-px-2 tw-py-2 tw-flex tw-items-center tw-space-x-2"
    :class="className"
  >
    <SvgoToastWait
      v-if="props.type === 'wait'"
      class="tw-h-5 tw-w-5 tw-fill-base-100"
    />
    <SvgoToastError
      v-if="props.type === 'error'"
      class="tw-h-5 tw-w-5 tw-fill-base-100"
    />
    <SvgoToastWarn
      v-if="props.type === 'info'"
      class="tw-h-5 tw-w-5 tw-fill-base-100"
    />
    <SvgoToastSuccess
      v-if="props.type === 'success'"
      class="tw-h-5 tw-w-5 tw-fill-base-100"
    />
    <span class="tw-flex-1 tw-text-center tw-font-semibold tw-leading-normal">
      <slot />
    </span>
    <div class="tw-h-5 tw-w-5"></div>
  </section>
</template>
<script setup lang="ts">
const props = defineProps<{ type: "success" | "error" | "info" | "wait" }>();
const className = computed(() => {
  switch (props.type) {
    case "success":
      return "tw-bg-success";
    case "wait":
      return "tw-bg-neutral/40";
    case "error":
      return "tw-bg-error";
    case "info":
      return "tw-bg-primary";
  }
});
</script>
